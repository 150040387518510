import {
  AppAssetPaths,
  AppRoute, AppRoutes
} from '../../app/app.types';

export interface NavigationMenuItem {
  title: string;
  route: AppRoute;
  icon?: string;
  subRoutes?: NavigationMenuItem[];
}

export const userSubRouteLinks: NavigationMenuItem[] = [
  {
    title: 'Students',
    route: AppRoutes.studentUsers,
  },
  {
    title: 'Key Contacts',
    route: AppRoutes.keyContactUsers,
  },
  {
    title: 'Counselors',
    route: AppRoutes.counselorUsers,
  },
  {
    title: 'District Admins',
    route: AppRoutes.districtAdminUsers,
  },
  {
    title: 'Community',
    route: AppRoutes.communityUsers,
  },
  {
    title: 'Institution Admins',
    route: AppRoutes.institutionAdminUsers,
  },
  {
    title: 'Deleted Users',
    route: AppRoutes.deletedUsers,
    icon: AppAssetPaths.icons.TRASH_WHITE,
  },
];

export const organizationSubRouteLinks: NavigationMenuItem[] = [
  {
    title: 'KTS Schools',
    route: AppRoutes.schoolOrganizations,
  },
  {
    title: 'Community',
    route: AppRoutes.communityOrganizations,
  },
];

export const resourceSubRouteLinks: NavigationMenuItem[] = [
  {
    title: 'Scholarships',
    route: AppRoutes.scholarships,
  },
  {
    title: 'Internships',
    route: AppRoutes.internships,
  },
  {
    title: 'Other Opportunities',
    route: AppRoutes.opportunities,
  },
];

export const featureSubRouteLinks: NavigationMenuItem[] = [
  {
    title: 'Career Clusters',
    route: AppRoutes.careerClusters,
  },
  {
    title: 'College Application Checklist',
    route: AppRoutes.collegeApplicationChecklist,
  },
  {
    title: 'Pathways',
    route: AppRoutes.pathways,
  },
  {
    title: 'Skills Training',
    route: AppRoutes.skillTrainings,
  },
  {
    title: 'Skills',
    route: AppRoutes.skills,
  },
  {
    title: 'Inquiries',
    route: AppRoutes.inquiries,
  },
];


export const alertSubRouteLinks: NavigationMenuItem[] = [
  {
    title: 'Notifications',
    route: AppRoutes.notifications,
  },
  {
    title: 'Announcements',
    route: AppRoutes.announcements,
  },
  {
    title: 'Master Key',
    route: AppRoutes.masterKey,
  },
];

export const approvalSubRouteLinks: NavigationMenuItem[] = [
  {
    title: 'Students',
    route: AppRoutes.studentApprovals,
  },
  {
    title: 'Transfers',
    route: AppRoutes.transferApprovals,
  },
  {
    title: 'Resources',
    route: AppRoutes.resourceApprovals,
  },
  {
    title: 'Notifications',
    route: AppRoutes.notificationApprovals,
  },
  {
    title: 'Applications',
    route: AppRoutes.applicationApprovals,
  },
  {
    title: 'Skill Trainings',
    route: AppRoutes.skillTrainingApprovals,
  },
];

// Main navigation links
export const navigationMenuLinks: NavigationMenuItem[] = [
  {
    title: 'My Dashboard',
    route: AppRoutes.dashboard,
    icon: AppAssetPaths.icons.MY_DASHBOARD,
  },
  {
    title: 'Users',
    route: AppRoutes.users,
    icon: AppAssetPaths.icons.USERS_WHITE,
    subRoutes: userSubRouteLinks,
  },
  {
    title: 'Organizations',
    route: AppRoutes.organizations,
    icon: AppAssetPaths.icons.ORGANIZATIONS_WHITE,
    subRoutes: organizationSubRouteLinks,
  },
  {
    title: 'Resources',
    route: AppRoutes.resources,
    icon: AppAssetPaths.icons.RESOURCES_WHITE,
    subRoutes: resourceSubRouteLinks,
  },
  {
    title: 'Features',
    route: AppRoutes.features,
    icon: AppAssetPaths.icons.STAR_WHITE,
    subRoutes: featureSubRouteLinks,
  },
  {
    title: 'Alerts',
    route: AppRoutes.alerts,
    icon: AppAssetPaths.icons.NOTIFICATIONS_WHITE,
    subRoutes: alertSubRouteLinks,
  },
  {
    title: 'Approvals',
    route: AppRoutes.approvals,
    icon: AppAssetPaths.icons.APPROVALS,
    subRoutes: approvalSubRouteLinks,
  },
];

