import {
  IconButton, TextField
} from '@mui/material';
import React from 'react';

import { AppAssetPaths } from '../../app/app.types';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Controller, useFormContext
} from 'react-hook-form';


interface SearchInputProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  className?: string;
  onClear?: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ name, className, onClear }) => {

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField className={className}
          {...field}
          type="text"
          size="small"
          placeholder="Search"
          value={field.value}
          InputProps={{
            startAdornment: <img src={AppAssetPaths.icons.SEARCH} />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{
                  width: 'auto',
                  height: 'auto',
                  visibility: field.value ? 'visible' : 'hidden',
                }}
                onClick={() => {
                  field.onChange('');
                  onClear && onClear();
                }}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchInput;
