
import React from 'react';
import {
  Navigate, Outlet, Route, Routes, useLocation
} from 'react-router-dom';
import {
  AppRoutes, canUseAuthComponent
} from '../../app/app.types';
import LandingPage from '../../pages/landing/LandingPage';
import MyDashboard from '../../pages/MyDashboard';
import Signin from '../../pages/landing/SignIn';
import NewUser from '../../pages/user/NewUser';
import UserDetails from '../../pages/user/UserDetails';
import UserList from '../../pages/user/UserList';
import { getSessionToken } from '../../util/localStorage';
import NavigationMenu from '../../components/navigation/NavigationMenu';
import PrivateRoute from './PrivateRoute';
import Signup from '../../pages/landing/Signup';
import ForgotPassword from '../../pages/landing/resetPasswordFlow/ForgotPassword';
import ResetPassword from '../../pages/landing/resetPasswordFlow/ResetPassword';
import PasswordChanged from '../../pages/landing/resetPasswordFlow/PasswordChanged';
import PopupMessage from '../../components/PopupMessage';
import {
  alertSelectors, clearAlert
} from '../../features/alert/alert.slice';
import {
  useAppDispatch, useAppSelector
} from '../hooks';
import { OrganizationType } from '../../features/organization/organization.model';
import OrganizationList from '../../pages/organization/OrganizationList';
import OrganizationDetail from '../../pages/organization/OrganizationDetail';
import CreateUpdateSchool from '../../pages/organization/CreateUpdateSchool';
import CreateUpdateCommunity from '../../pages/organization/CreateUpdateCommunity';
import ResourceList from '../../pages/resource/ResourceList';
import FeatureList from '../../pages/feature/FeatureList';
import AlertList from '../../pages/alert/AlertList';
import NotificationDetails from '../../pages/alert/notifications/NotificationDetails';
import CreateEditNotification from '../../pages/alert/notifications/CreateEditNotification';
import AnnouncementDetails from '../../pages/alert/announcement/AnnouncementDetails';
import CreateEditAnnouncement from '../../pages/alert/announcement/CreateEditAnnouncement';
import { AlertType } from '../../constants/tables/alertTable.types';
import { ApprovalTypeTabs } from '../../constants/tables/approvalTable.types';
import ApprovalList from '../../pages/approval/ApprovalList';
import OrganizationSettings from '../../pages/organization/OrganizationSettings';
import {
  deletedUser, UserRole
} from '../../features/user/user.model';
import ScholarshipDetails from '../../pages/resource/scholarships/ScholarshipDetails';
import ScholarshipApplicationDetails from '../../pages/resource/scholarships/ScholarshipApplicationDetails';
import { ResourceType } from '../../features/resources/resources.model';
import OpportunityDetails from '../../pages/resource/opportunities/OpportunityDetails';
import ResourceApplicantsList from '../../pages/resource/ResourceApplicantsList';
import CreateEditResource from '../../pages/resource/CreateEditResource';
import { FeatureTabs } from '../../constants/tables/pathwayTable.types';
import PathwayDetails from '../../pages/feature/pathways/PathwayDetails';
import SkillTrainingDetails from '../../pages/feature/skillTrainings/SkillTrainingDetails';
import SkillsList from '../../pages/feature/skills/SkillsList';
import SkillDetails from '../../pages/feature/skills/SkillDetails';
import CreateSkill from '../../pages/feature/skills/CreateSkill';
import CreateSkillTraining from '../../pages/feature/skillTrainings/CreateSkillTraining';
import CareerClusterList from '../../pages/feature/careerClusters/CareerClusterList';
import CareerClusterDetails from '../../pages/feature/careerClusters/CareerClusterDetails';
import MasterKeyDetails from '../../pages/alert/masterKey/MasterKeyDetails';
import EditCareerCluster from '../../pages/feature/careerClusters/EditCareerCluster';
import CreateEditMasterKey from '../../pages/alert/masterKey/CreateEditMasterKey';
import CreateEditPathway from '../../pages/feature/pathways/CreateEditPathway';
import MasterKeySubmissionList from '../../pages/alert/masterKey/MasterKeySubmissionList';
import MasterKeySubmissionDetails from '../../pages/alert/masterKey/MasterKeySubmissionDetails';
import { userSelectors } from '../../features/user/user.slice';
import ResourceTagsList from '../../pages/resource/resourceTags/ResourceTagsList';
import CreateEditResourceTag from '../../pages/resource/resourceTags/CreateEditResourceTag';
import ResourceTagDetails from '../../pages/resource/resourceTags/ResourceTagDetails';
import CollegeApplicationChecklistPage from '../../pages/feature/collegeApplicationChecklist/CollegeApplicationChecklistPage';
import { CollegeApplicationChecklistTabs } from '../../constants/tables/collegeApplicationChecklistTable.types';
import CollegeDetails from '../../pages/feature/collegeApplicationChecklist/colleges/CollegeDetails';
import CreateEditCollege from '../../pages/feature/collegeApplicationChecklist/colleges/CreateEditCollege';
import FinancialAidInfoDetails from '../../pages/feature/collegeApplicationChecklist/financialAid/FinancialAidInfoDetails';
import FafsaLinksDetails from '../../pages/feature/collegeApplicationChecklist/financialAid/FafsaLinksDetails';
import EditFinancialAidInfo from '../../pages/feature/collegeApplicationChecklist/financialAid/EditFinancialAidInfo';
import EditFafsaLinks from '../../pages/feature/collegeApplicationChecklist/financialAid/EditFafsaLinks';
import InquiryList from '../../pages/feature/inquiries/InquiryList';
import BulkAccountCreateWrapper from '../../pages/user/bulkAccountCreation/BulkAccountCreateWrapper';


interface AppRouterProps { }

const AppRouter: React.FC<AppRouterProps> = () => {

  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const isAuthenticated = !!getSessionToken();
  const alert = useAppSelector(alertSelectors.selectAlert);
  const user = useAppSelector(userSelectors.selectUser);

  return (
    <>
      <Routes>
        {/* index determines the default component if none of the child routes match */}
        <Route index element={<Navigate to={AppRoutes.signIn.path} />} />

        {/* LANDING PAGE ROUTES */}
        <Route path={AppRoutes.signIn.path} element={
          isAuthenticated ? (
            <Navigate to={state && state.from ? state.from : AppRoutes.dashboard.path} />
          ) : (
            <LandingPage pageContent={<Signin />} />
          )
        } />
        <Route path={AppRoutes.signUp.path} element={<LandingPage pageContent={<Signup />} />} />
        <Route path={AppRoutes.forgotPassword.path} element={<LandingPage pageContent={<ForgotPassword />} />} />
        <Route path={AppRoutes.resetPassword.path} element={<LandingPage pageContent={<ResetPassword />} />} />
        <Route path={AppRoutes.passwordUpdated.path} element={<LandingPage pageContent={<PasswordChanged />} />} />

        {/* NAVIGATION MENU ROUTES */}
        <Route path={AppRoutes.app.path} element={
          <PrivateRoute element={<NavigationMenu />} />
        }>
          <Route index element={<MyDashboard />} />
          <Route path={AppRoutes.dashboard.path} element={
            <PrivateRoute element={<MyDashboard />} />
          } />

          {/* USER ROUTES */}
          <Route path={AppRoutes.users.path} element={
            <PrivateRoute element={<Outlet />} authorizedRoles={AppRoutes.users.authorizedRoles} />
          }>
            <Route index element={
              <PrivateRoute element={<UserList initialTab={UserRole.student} />} />
            } />
            <Route path={AppRoutes.studentUsers.path} element={
              <PrivateRoute element={<UserList initialTab={UserRole.student} />} authorizedRoles={AppRoutes.studentUsers.authorizedRoles} />
            } />
            <Route path={AppRoutes.keyContactUsers.path} element={
              <PrivateRoute element={<UserList initialTab={UserRole.keyContact} />} authorizedRoles={AppRoutes.keyContactUsers.authorizedRoles} />
            } />
            <Route path={AppRoutes.counselorUsers.path} element={
              <PrivateRoute element={<UserList initialTab={UserRole.counselor} />} authorizedRoles={AppRoutes.counselorUsers.authorizedRoles} />
            } />
            <Route path={AppRoutes.districtAdminUsers.path} element={
              <PrivateRoute element={<UserList initialTab={UserRole.districtAdmin} />} authorizedRoles={AppRoutes.districtAdminUsers.authorizedRoles} />
            } />
            <Route path={AppRoutes.communityUsers.path} element={
              <PrivateRoute element={<UserList initialTab={UserRole.community} />} authorizedRoles={AppRoutes.communityUsers.authorizedRoles} />
            } />
            <Route path={AppRoutes.institutionAdminUsers.path} element={
              <PrivateRoute element={<UserList initialTab={UserRole.institutionAdmin} />} authorizedRoles={AppRoutes.institutionAdminUsers.authorizedRoles} />
            } />
            <Route path={AppRoutes.deletedUsers.path} element={
              <PrivateRoute element={<UserList initialTab={deletedUser} />} authorizedRoles={AppRoutes.deletedUsers.authorizedRoles} />
            } />

            <Route path={AppRoutes.newUser.path} element={
              <PrivateRoute element={<NewUser />} authorizedRoles={AppRoutes.newUser.authorizedRoles} />
            } />
            <Route path={AppRoutes.userDetails.path} element={
              <PrivateRoute element={<UserDetails />} authorizedRoles={AppRoutes.userDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.bulkUserCreate.path} element={
              <PrivateRoute element={<BulkAccountCreateWrapper />} authorizedRoles={AppRoutes.bulkUserCreate.authorizedRoles} />
            } />
          </Route>

          {/* ORGANIZATION ROUTES */}
          <Route path={AppRoutes.organizations.path} element={
            <PrivateRoute element={<Outlet />} authorizedRoles={AppRoutes.organizations.authorizedRoles} />
          }>
            <Route index element={
              <PrivateRoute element={<OrganizationList initialTab={OrganizationType.School} />} />
            } />
            <Route path={AppRoutes.schoolOrganizations.path} element={
              <PrivateRoute element={<OrganizationList initialTab={OrganizationType.School} />} authorizedRoles={AppRoutes.schoolOrganizations.authorizedRoles} />
            } />
            <Route path={AppRoutes.communityOrganizations.path} element={
              <PrivateRoute element={<OrganizationList initialTab={OrganizationType.Community} />} authorizedRoles={AppRoutes.communityOrganizations.authorizedRoles} />
            } />
            <Route path={AppRoutes.schoolDetail.path} element={
              <PrivateRoute element={<OrganizationDetail />} authorizedRoles={AppRoutes.schoolDetail.authorizedRoles} />
            } />
            <Route path={AppRoutes.communityDetail.path} element={
              <PrivateRoute element={<OrganizationDetail />} authorizedRoles={AppRoutes.communityDetail.authorizedRoles} />
            } />
            <Route path={AppRoutes.newSchool.path} element={
              <PrivateRoute element={<CreateUpdateSchool />} authorizedRoles={AppRoutes.newSchool.authorizedRoles} />
            } />
            <Route path={AppRoutes.editSchool.path} element={
              <PrivateRoute element={<CreateUpdateSchool />} authorizedRoles={AppRoutes.editSchool.authorizedRoles} />
            } />
            <Route path={AppRoutes.editCommunity.path} element={
              <PrivateRoute element={<CreateUpdateCommunity />} authorizedRoles={AppRoutes.editCommunity.authorizedRoles} />
            } />
          </Route>

          {/* RESOURCE ROUTES */}
          <Route path={AppRoutes.resources.path} element={
            <PrivateRoute element={<Outlet />} authorizedRoles={AppRoutes.resources.authorizedRoles} />
          }>
            <Route index element={
              <PrivateRoute element={<ResourceList initialTab={ResourceType.scholarship} />} />
            } />
            <Route path={AppRoutes.scholarships.path} element={
              <PrivateRoute element={<ResourceList initialTab={ResourceType.scholarship} />} authorizedRoles={AppRoutes.scholarships.authorizedRoles} />
            } />
            <Route path={AppRoutes.internships.path} element={
              <PrivateRoute element={<ResourceList initialTab={ResourceType.internship} />} authorizedRoles={AppRoutes.internships.authorizedRoles} />
            } />
            <Route path={AppRoutes.opportunities.path} element={
              <PrivateRoute element={<ResourceList initialTab={ResourceType.opportunity} />} authorizedRoles={AppRoutes.opportunities.authorizedRoles} />
            } />
            <Route path={AppRoutes.scholarshipDetails.path} element={
              <PrivateRoute element={<ScholarshipDetails />} authorizedRoles={AppRoutes.scholarshipDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.scholarshipApplicationDetails.path} element={
              <PrivateRoute element={<ScholarshipApplicationDetails />} authorizedRoles={AppRoutes.scholarshipApplicationDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.internshipDetails.path} element={
              <PrivateRoute element={<OpportunityDetails />} authorizedRoles={AppRoutes.internshipDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.opportunityDetails.path} element={
              <PrivateRoute element={<OpportunityDetails />} authorizedRoles={AppRoutes.opportunityDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.resourceApplicants.path} element={
              <PrivateRoute element={<ResourceApplicantsList />} authorizedRoles={AppRoutes.resourceApplicants.authorizedRoles} />
            } />
            <Route path={AppRoutes.createResource.path} element={
              <PrivateRoute element={<CreateEditResource />} />
            } />
            <Route path={AppRoutes.editResource.path} element={
              <PrivateRoute element={<CreateEditResource />} />
            } />
            {/* RESOURCE TAGS */}
            <Route path={AppRoutes.resourceTags.path} element={
              <PrivateRoute element={<ResourceTagsList />} />
            } />
            <Route path={AppRoutes.resourceTagDetails.path} element={
              <PrivateRoute element={<ResourceTagDetails />} />
            } />
            <Route path={AppRoutes.createResourceTag.path} element={
              <PrivateRoute element={<CreateEditResourceTag />} />
            } />
            <Route path={AppRoutes.editResourceTag.path} element={
              <PrivateRoute element={<CreateEditResourceTag />} />
            } />
          </Route>

          {/* FEATURE ROUTES */}
          <Route path={AppRoutes.features.path} element={
            <PrivateRoute element={<Outlet />} authorizedRoles={AppRoutes.features.authorizedRoles} />
          }>
            <Route index element={
              <PrivateRoute element={
                <FeatureList
                  initialTab={(canUseAuthComponent(user.roles, AppRoutes.pathways.authorizedRoles) && !user.isDistrictInstitutionAdmin) ? FeatureTabs.pathways : FeatureTabs.skillTrainings}
                />
              } />
            } />
            <Route path={AppRoutes.pathways.path} element={
              <PrivateRoute element={<FeatureList initialTab={FeatureTabs.pathways} />} authorizedRoles={AppRoutes.pathways.authorizedRoles} />
            } />
            <Route path={AppRoutes.pathwayDetails.path} element={
              <PrivateRoute element={<PathwayDetails />} authorizedRoles={AppRoutes.pathwayDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.createPathway.path} element={
              <PrivateRoute element={<CreateEditPathway />} authorizedRoles={AppRoutes.createPathway.authorizedRoles} />
            } />
            <Route path={AppRoutes.editPathway.path} element={
              <PrivateRoute element={<CreateEditPathway />} authorizedRoles={AppRoutes.editPathway.authorizedRoles} />
            } />

            <Route path={AppRoutes.skillTrainings.path} element={
              <PrivateRoute element={<FeatureList initialTab={FeatureTabs.skillTrainings} />} authorizedRoles={AppRoutes.skillTrainings.authorizedRoles} />
            } />
            {/* ---------- CAREER CLUSTERS ---------- */}
            <Route path={AppRoutes.careerClusters.path} element={
              <PrivateRoute element={<CareerClusterList />} authorizedRoles={AppRoutes.careerClusters.authorizedRoles} />
            } />
            <Route path={AppRoutes.careerClusterDetails.path} element={
              <PrivateRoute element={<CareerClusterDetails />} />
            } />
            <Route path={AppRoutes.editCareerCluster.path} element={
              <PrivateRoute element={<EditCareerCluster />} authorizedRoles={AppRoutes.editCareerCluster.authorizedRoles} />
            } />
            <Route path={AppRoutes.skillTrainingDetails.path} element={
              <PrivateRoute element={<SkillTrainingDetails />} authorizedRoles={AppRoutes.skillTrainingDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.createSkillTraining.path} element={
              <PrivateRoute element={<CreateSkillTraining />} authorizedRoles={AppRoutes.createSkillTraining.authorizedRoles} />
            } />
            <Route path={AppRoutes.editSkillTraining.path} element={
              <PrivateRoute element={<CreateSkillTraining />} authorizedRoles={AppRoutes.editSkillTraining.authorizedRoles} />
            } />
            <Route path={AppRoutes.skills.path} element={
              <PrivateRoute element={<SkillsList />} authorizedRoles={AppRoutes.skills.authorizedRoles} />
            } />
            <Route path={AppRoutes.skillDetails.path} element={
              <PrivateRoute element={<SkillDetails />} authorizedRoles={AppRoutes.skillDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.createSkill.path} element={
              <PrivateRoute element={<CreateSkill />} authorizedRoles={AppRoutes.createSkill.authorizedRoles} />
            } />
            <Route path={AppRoutes.editSkill.path} element={
              <PrivateRoute element={<CreateSkill />} authorizedRoles={AppRoutes.editSkill.authorizedRoles} />
            } />

            {/* ---------- COLLEGE APPLICATION CHECKLIST ---------- */}
            <Route path={AppRoutes.collegeApplicationChecklist.path} element={
              <PrivateRoute element={<CollegeApplicationChecklistPage initialTab={CollegeApplicationChecklistTabs.applicants} />}
                authorizedRoles={AppRoutes.collegeApplicationChecklist.authorizedRoles} />
            } />
            <Route path={AppRoutes.collegeApplicationChecklistColleges.path} element={
              <PrivateRoute element={<CollegeApplicationChecklistPage initialTab={CollegeApplicationChecklistTabs.colleges} />}
                authorizedRoles={AppRoutes.collegeApplicationChecklistColleges.authorizedRoles} />
            } />
            <Route path={AppRoutes.collegeApplicationChecklistFinancialAidList.path} element={
              <PrivateRoute element={<CollegeApplicationChecklistPage initialTab={CollegeApplicationChecklistTabs.financialAid} />}
                authorizedRoles={AppRoutes.collegeApplicationChecklistFinancialAidList.authorizedRoles} />
            } />
            <Route path={AppRoutes.createCollegeApplicationChecklistCollege.path} element={
              <PrivateRoute element={<CreateEditCollege />} authorizedRoles={AppRoutes.createCollegeApplicationChecklistCollege.authorizedRoles} />
            } />
            <Route path={AppRoutes.editCollegeApplicationChecklistCollege.path} element={
              <PrivateRoute element={<CreateEditCollege />} authorizedRoles={AppRoutes.createCollegeApplicationChecklistCollege.authorizedRoles} />
            } />
            <Route path={AppRoutes.collegeApplicationChecklistCollegeDetails.path} element={
              <PrivateRoute element={<CollegeDetails />} authorizedRoles={AppRoutes.collegeApplicationChecklist.authorizedRoles} />
            } />
            <Route path={AppRoutes.financialAidInfoDetails.path} element={
              <PrivateRoute element={<FinancialAidInfoDetails />} authorizedRoles={AppRoutes.financialAidInfoDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.editFinancialAidInfo.path} element={
              <PrivateRoute element={<EditFinancialAidInfo />} authorizedRoles={AppRoutes.editFinancialAidInfo.authorizedRoles} />
            } />
            <Route path={AppRoutes.fafsaLinksDetails.path} element={
              <PrivateRoute element={<FafsaLinksDetails />} authorizedRoles={AppRoutes.fafsaLinksDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.editFafsaLinks.path} element={
              <PrivateRoute element={<EditFafsaLinks />} authorizedRoles={AppRoutes.editFafsaLinks.authorizedRoles} />
            } />
            <Route path={AppRoutes.inquiries.path} element={
              <PrivateRoute element={<InquiryList />} authorizedRoles={AppRoutes.inquiries.authorizedRoles} />
            } />
          </Route>

          {/* ALERT ROUTES */}
          <Route path={AppRoutes.alerts.path} element={
            <PrivateRoute element={<Outlet />} authorizedRoles={AppRoutes.alerts.authorizedRoles} />
          }>
            <Route index element={
              <PrivateRoute element={<AlertList initialTab={AlertType.Notification} />} />
            } />
            <Route path={AppRoutes.notifications.path} element={
              <PrivateRoute element={<AlertList initialTab={AlertType.Notification} />} authorizedRoles={AppRoutes.notifications.authorizedRoles} />
            } />
            <Route path={AppRoutes.notificationDetails.path} element={
              <PrivateRoute element={<NotificationDetails />} authorizedRoles={AppRoutes.notificationDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.createNotification.path} element={
              <PrivateRoute element={<CreateEditNotification />} authorizedRoles={AppRoutes.createNotification.authorizedRoles} />
            } />
            <Route path={AppRoutes.editNotification.path} element={
              <PrivateRoute element={<CreateEditNotification />} authorizedRoles={AppRoutes.editNotification.authorizedRoles} />
            } />
            <Route path={AppRoutes.announcements.path} element={
              <PrivateRoute element={<AlertList initialTab={AlertType.Announcement} />} authorizedRoles={AppRoutes.announcements.authorizedRoles} />
            } />
            <Route path={AppRoutes.announcementDetails.path} element={
              <PrivateRoute element={<AnnouncementDetails />} authorizedRoles={AppRoutes.announcementDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.createAnnouncement.path} element={
              <PrivateRoute element={<CreateEditAnnouncement />} authorizedRoles={AppRoutes.createAnnouncement.authorizedRoles} />
            } />
            <Route path={AppRoutes.editAnnouncement.path} element={
              <PrivateRoute element={<CreateEditAnnouncement />} authorizedRoles={AppRoutes.editAnnouncement.authorizedRoles} />
            } />
            <Route path={AppRoutes.masterKey.path} element={
              <PrivateRoute element={<AlertList initialTab={AlertType.MasterKey} />} authorizedRoles={AppRoutes.masterKey.authorizedRoles} />
            } />
            <Route path={AppRoutes.masterKeyDetails.path} element={
              <PrivateRoute element={<MasterKeyDetails />} authorizedRoles={AppRoutes.masterKeyDetails.authorizedRoles} />
            } />
            <Route path={AppRoutes.createMasterKey.path} element={
              <PrivateRoute element={<CreateEditMasterKey />} authorizedRoles={AppRoutes.createMasterKey.authorizedRoles} />
            } />
            <Route path={AppRoutes.editMasterKey.path} element={
              <PrivateRoute element={<CreateEditMasterKey />} authorizedRoles={AppRoutes.editMasterKey.authorizedRoles} />
            } />
            <Route path={AppRoutes.masterKeySubmissions.path} element={
              <PrivateRoute element={<MasterKeySubmissionList />} authorizedRoles={AppRoutes.masterKeySubmissions.authorizedRoles} />
            } />
            <Route path={AppRoutes.masterKeySubmissionDetails.path} element={
              <PrivateRoute element={<MasterKeySubmissionDetails />} authorizedRoles={AppRoutes.masterKeySubmissionDetails.authorizedRoles} />
            } />
          </Route>

          {/* APPROVAL ROUTES */}
          <Route path={AppRoutes.approvals.path} element={
            <PrivateRoute element={<Outlet />} authorizedRoles={AppRoutes.approvals.authorizedRoles} />
          }>
            <Route index element={
              <PrivateRoute element={<ApprovalList initialTab={ApprovalTypeTabs.StudentApprovals} />} />
            } />
            <Route path={AppRoutes.studentApprovals.path} element={
              <PrivateRoute element={<ApprovalList initialTab={ApprovalTypeTabs.StudentApprovals} />} authorizedRoles={AppRoutes.studentApprovals.authorizedRoles} />
            } />
            <Route path={AppRoutes.transferApprovals.path} element={
              <PrivateRoute element={<ApprovalList initialTab={ApprovalTypeTabs.TransferApprovals} />} authorizedRoles={AppRoutes.transferApprovals.authorizedRoles} />
            } />
            <Route path={AppRoutes.resourceApprovals.path} element={
              <PrivateRoute element={<ApprovalList initialTab={ApprovalTypeTabs.ResourceApprovals} />} authorizedRoles={AppRoutes.resourceApprovals.authorizedRoles} />
            } />
            <Route path={AppRoutes.notificationApprovals.path} element={
              <PrivateRoute element={<ApprovalList initialTab={ApprovalTypeTabs.NotificationApprovals} />} authorizedRoles={AppRoutes.notificationApprovals.authorizedRoles} />
            } />
            <Route path={AppRoutes.applicationApprovals.path} element={
              <PrivateRoute element={<ApprovalList initialTab={ApprovalTypeTabs.ApplicationApprovals} />} authorizedRoles={AppRoutes.applicationApprovals.authorizedRoles} />
            } />
            <Route path={AppRoutes.skillTrainingApprovals.path} element={
              <PrivateRoute element={<ApprovalList initialTab={ApprovalTypeTabs.SkillTrainingApprovals} />} authorizedRoles={AppRoutes.skillTrainingApprovals.authorizedRoles} />
            } />
          </Route>

          <Route path={AppRoutes.organizationSettings.path} element={
            <PrivateRoute element={<OrganizationSettings />} authorizedRoles={AppRoutes.organizationSettings.authorizedRoles} />
          } />

        </Route>

        <Route path="*" element={<Navigate to={AppRoutes.signIn.path} />} />
      </Routes>

      <PopupMessage
        alertObj={alert}
        onClose={() => dispatch(clearAlert())}
      />
    </>
  );
};

export default AppRouter;
