import {
  Pagination, Tab, Tabs
} from '@mui/material';
import { Box } from '@mui/system';
import {
  DataGrid, GridSortModel
} from '@mui/x-data-grid';
import React, {
  useEffect, useState
} from 'react';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import TableRowCount from '../../../components/table/TableRowCount';
import { TableState } from '../../../constants/tables/table.types';
import SearchInput from '../../../components/table/SearchInput';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { InquiryListQueryParams } from '../../../features/inquiries/inquiries.model';
import './InquiryList.scss';
import { getInquiryList } from '../../../features/inquiries/inquiries.thunks';
import { inquirySelectors } from '../../../features/inquiries/inquiries.slice';
import { inquiryListColumns } from '../../../constants/tables/inquiryTable.types';


interface InquiryListProps { }


const InquiryList: React.FC<InquiryListProps> = () => {

  const dispatch = useAppDispatch();

  const inquiryList = useAppSelector(inquirySelectors.selectInquiryList);
  const totalInquiryCount = useAppSelector(inquirySelectors.selectInquiryListCount);

  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: inquiryListColumns,
    rows: [],
    loading: false,
  });

  const [
    inquiryListQuery,
    setInquiryListQuery,
  ] = useState<InquiryListQueryParams>({
    offset: 0,
    limit: undefined,
    search: undefined,
    sortModel: undefined,
  });

  // Filtering list
  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      page: 1,
      rows: [],
      loading: true,
    }));


    dispatch(getInquiryList({
      ...inquiryListQuery,
      offset: 0,
    }));

  }, [
    inquiryListQuery,
  ]);

  // Loading more data for pagination
  useEffect(() => {
    if (tableState.page < inquiryList.length / tableState.pageSize || !inquiryList.length || inquiryList.length >= totalInquiryCount) return;

    setTableState((prev) => ({
      ...prev,
      loading: true,
    }));

    dispatch(getInquiryList({
      ...inquiryListQuery,
      offset: tableState.pageSize * (tableState.page - 1),
    }));
  }, [
    tableState.page,
    tableState.pageSize,
  ]);

  useEffect(() => {
    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: inquiryList,
    }));
  }, [
    inquiryList,
  ]);

  const formValidationSchema = yup.object().shape({
    search: yup.string(),
  });

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    setInquiryListQuery((prev) => ({
      ...prev,
      search: values.search,
    }));
  };

  return (
    <div id="inquiries-list">
      <Box className="page-content card-background">
        <Tabs
          className="main-page-tabs"
          variant="fullWidth"
          value="App Inquiries"
        >
          <Tab label="App Inquiries" value="App Inquiries" />
        </Tabs>

        <div className="top-container flex_jbetween_acenter">
          <form onSubmit={handleSubmit(submitForm)}>
            <FormProvider {...methods}>
              <SearchInput
                className="main-input search"
                name="search"
                onClear={handleSubmit(submitForm)}
              />
            </FormProvider>
          </form>
        </div>

        <DataGrid
          className="table"
          {...tableState}
          components={{
            Pagination: Pagination,
          }}
          getRowHeight={() => 'auto'}
          componentsProps={{
            pagination: {
              page: tableState.page,
              count: Math.ceil(totalInquiryCount / tableState.pageSize),
              onChange: (_: void, page: number) => {
                setTableState((prev) => ({
                  ...prev,
                  page,
                }));
              },
            },
          }}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={inquiryListQuery.sortModel}
          onSortModelChange={(newSortModel: GridSortModel) => {
            setInquiryListQuery((prev) => ({
              ...prev,
              sortModel: newSortModel,
            }));
          }}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
        />

        <TableRowCount
          length={inquiryList.length}
          pageSize={tableState.pageSize}
          page={tableState.page}
          totalCount={totalInquiryCount}
        />
      </Box>
    </div>
  );
};

export default InquiryList;
