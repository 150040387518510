import {
  List, ListItem, Menu, MenuItem
} from '@mui/material';
import React, {
  useEffect, useState
} from 'react';
import {
  Outlet, useLocation, useNavigate
} from 'react-router-dom';
import {
  AppAssetPaths, AppRoutes, canUseAuthComponent, isPostSecondaryAdmin
} from '../../app/app.types';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import {
  NavigationMenuItem, navigationMenuLinks
} from './navigation.types';
import { userSelectors } from '../../features/user/user.slice';
import './NavigationMenu.scss';

import Drawer from '@mui/material/Drawer';

import ProfileNav from './ProfileNav';
import {
  navMenuAnchorOrigin, navMenuPaperProps, navMenuTransformOrigin
} from '../../constants/menu.types';
import {
  clearPageHeader, navigationSelectors, setPageHeader, setSelectedNavSection
} from '../../features/navigation/navigation.slice';
import AppFooter from '../AppFooter';
import { UserRole } from '../../features/user/user.model';
import { checkFeaturesHigherEdCounselor } from '../../app/app.permissions';


interface NavigationMenuProps { }

const NavigationMenu: React.FC<NavigationMenuProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const user = useAppSelector(userSelectors.selectUser);
  const pageHeader = useAppSelector(navigationSelectors.selectPageHeader);
  const selectedNavSection = useAppSelector(navigationSelectors.selectSelectedNavSection);

  const [
    anchorElement,
    setAnchorElement,
  ] = useState<null | HTMLElement>(null);

  useEffect(() => {
    // Check the current location path to set the selected item within the nav bar
    navigationMenuLinks.map((link) => {
      if (location.pathname.includes(link.route.path)) {
        link.title != 'My Dashboard' && dispatch(setPageHeader(link.title));
        dispatch(setSelectedNavSection(link));
      }
      (link && link.subRoutes) && link.subRoutes.map((sub) => {
        if (location.pathname.includes(sub.route.path)) {
          dispatch(setPageHeader(link.title));
          dispatch(setSelectedNavSection(link));
        }
      });
    });
  }, [
    location.pathname,
  ]);


  const checkApprovalSecondary = (title: string): boolean => {
    // Only for Approvals at the moment
    //   Returns False if user is only a admin for post secondary education
    if (title === 'Approvals' && isPostSecondaryAdmin(user)) {
      if (!user.roles.includes(UserRole.admin) || !user.roles.includes(UserRole.highSchoolKeyContact) || !user.roles.includes(UserRole.middleSchoolKeyContact))  {
        return false;
      }
    }
    return true;
  };

  const handleMyDashboardRoute = (link: NavigationMenuItem, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (link.title === 'My Dashboard') {
      dispatch(clearPageHeader());
    }
    navigate(link.route.path);
  };

  return (
    <div id="navigation-menu">
      <Drawer className="nav-drawer"
        variant="permanent"
        anchor="left"
        PaperProps={{
          style: {
            width: '74px',
            background: '#2F2F51',
            border: 'none',
            borderRadius: '0em .5em .5em 0em',
            boxShadow: '0px 0px 8px rgba(47, 47, 81, 0.5)',
          },
        }}
        onClick={() => setAnchorElement(null)}>

        <div className="drawer-header flex_row_jcenter_acenter">
          <img src={AppAssetPaths.icons.KTS_ICON_WHITE} alt="KTS Icon" />
        </div>

        <List className="white-out">
          {navigationMenuLinks.map((link, i) =>
            (canUseAuthComponent(user.roles, link.route.authorizedRoles) && checkApprovalSecondary(link.title) && checkFeaturesHigherEdCounselor(link.title, user.roles)) && (
              <List disablePadding key={i}>
                <ListItem
                  className={`nav-header ${selectedNavSection == link && 'selected-item'}`}
                  key={i}
                  button
                  onClick={(e) => {
                    handleMyDashboardRoute(link, e);
                    setAnchorElement(null);
                  }}
                  onMouseOver={(e) => setAnchorElement(e.currentTarget)}
                  id={i.toString()}
                >
                  {link.icon && (
                    <img className="link-icon" src={link.icon} alt="Nav link" />
                  )}
                </ListItem>
                {link.subRoutes && !!link.subRoutes.length && (
                  <Menu className="nav-menu flex_row_jcenter_acenter"
                    anchorEl={anchorElement}
                    open={Boolean(anchorElement) && anchorElement?.getAttribute('id') === i.toString()}
                    onClose={() => setAnchorElement(null)}
                    autoFocus={false}
                    PaperProps={navMenuPaperProps}
                    transformOrigin={navMenuTransformOrigin}
                    anchorOrigin={navMenuAnchorOrigin}>
                    <MenuItem className="nav-menu-button header-button"
                      onClick={(e) => {
                        navigate(link.route.path);
                        setAnchorElement(null);
                      }}>
                      {link.title}
                    </MenuItem>
                    {link.subRoutes.map((sub, e: number) => (
                      (canUseAuthComponent(user.roles, sub.route.authorizedRoles)) && (
                        (user.isDistrictInstitutionAdmin && sub.route == AppRoutes.pathways) ? null : (
                          <MenuItem className="nav-menu-button"
                            onClick={() => {
                              navigate(sub.route.path);
                              setAnchorElement(null);
                            }}
                            key={e}>
                            {sub.icon && (
                              <img className="sublink-icon" src={sub.icon} alt="Nav link" />
                            )}
                            {sub.title}
                          </MenuItem>
                        ))
                    ))}
                  </Menu>
                )}
              </List>
            ))}
        </List>
      </Drawer>

      <div className="content flex_col"
        onClick={(e) => {
          setAnchorElement(null);
        }}
        onMouseOver={() => setAnchorElement(null)}
      >
        <div className="flex_acenter page-header">
          <h2>{pageHeader}</h2>
          <ProfileNav />
        </div>

        {/* This Outlet is used to display child elements in the router,
            while allowing the parent UI to continue displaying */}
        <Outlet />

        <AppFooter />
      </div>
    </div>
  );
};

export default NavigationMenu;
