import { AuthComponent } from './app.types';
import { UserRole } from '../features/user/user.model';

export const AppPermissions: Record<string, AuthComponent> = {
  manualEmailVerification: {
    authorizedRoles: [
      UserRole.admin,
      UserRole.keyContact,
      UserRole.institutionAdmin,
    ],
  },
};

export const checkFeaturesHigherEdCounselor = (title: string, userRoles: UserRole[]): boolean => {
  // Only for Features at the moment
  //   Returns False if user is univeristy counselor (Higher Ed Counselors cannot access features)
  if (title === 'Features' && userRoles.includes(UserRole.universityCounselor)) {
    return false;
  }
  return true;
};
