/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosRequestConfig, AxiosResponse
} from 'axios';
import { loadConfigData } from '../features/config/config.thunks';
import {
  getSessionToken, removeSessionToken
} from '../util/localStorage';
import { configStore } from './configStore';

const config = '/config.json';

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum HTTP_ERROR_CODES {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  LIMIT_REACHED = 405,
  CONFLICT = 409,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
}

export const getApiBaseUrl = async () => {
  const stateUrl = configStore.getState().config.environmentConfig?.data?.api_url;
  if (stateUrl) {
    return stateUrl;
  }
  else {
    configStore.dispatch(loadConfigData());
  }

  const response = await axios.get(config);
  const envData = await response.data;
  const apiUrl = envData.api_url;

  return apiUrl;
};

export const getStudentAppBaseUrl = async () => {
  const stateUrl = configStore.getState().config.environmentConfig?.data?.student_base_url;
  if (stateUrl) {
    return stateUrl;
  }
  else {
    configStore.dispatch(loadConfigData());
  }

  const response = await axios.get(config);
  const envData = await response.data;
  const studentUrl = envData.student_base_url;

  return studentUrl;
};

axios.interceptors.request.use(async (config) => {
  const token = await getSessionToken();
  if (token) {
    config.headers = {
      'Authorization': `Bearer ${token}`,
    };
  }

  return config;
}, (error) => {
  console.error('axios interceptor error :>> ', error);
  return Promise.reject(error);
});

export const requestFactory = async (
  method: HTTP_METHOD,
  urlPath: string,
  headers?: Record<string, string>,
  body?: Record<string, any>,
  isRedirect = true,
  localFile = false
): Promise<AxiosResponse<any>> => {
  try {
    const baseUrl = await getApiBaseUrl();
    let apiUrl;
    if (localFile) { // we don't need the API url if we are getting local files
      apiUrl = urlPath;
    }
    else {
      apiUrl = baseUrl + urlPath;
    }

    const request: AxiosRequestConfig = {
      url: apiUrl,
      method: method,
      headers: headers,
      data: body,
    };

    return await axios.request(request);

  } catch (error: any) {

    if (!error.response) {
      return new Promise((_, reject) => reject(error));
    }

    if (error.response.status >= HTTP_ERROR_CODES.INTERNAL_SERVER_ERROR && isRedirect) {
      console.error('SERVER ERROR :>> ', error);
    }
    else if (error.response.status === HTTP_ERROR_CODES.UNAUTHORIZED) {
      console.error('USER ERROR :>> ', error);
    }

    if (error.response.data.message === 'jwt expired') {
      removeSessionToken();
      location.reload();
    }

    return new Promise((_, reject) => reject(error));
  }
};
