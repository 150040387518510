import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, IconButton, Tooltip
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { startCase } from 'lodash';

import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import {
  AppAssetPaths, canUseAuthComponent
} from '../../../app/app.types';
import {
  EmptyUserOverview,
  UserOverview,
  getGenericUserType,
  User,
  UserRole
} from '../../../features/user/user.model';
import {
  approveStudent,
  rejectStudent,
  approveTransfer,
  rejectTransfer,
  restoreDeletedUser,
  generateConfirmationCode
} from '../../../features/user/user.thunks';
import { setAlert } from '../../../features/alert/alert.slice';
import ExpandableCard from '../ExpandableCard';
import StudentDetail from './StudentDetail';
import UserDetail from './UserDetail';
import EditUserDetail from './EditUserDetail';
import ApproveRejectCard from '../ApproveRejectCard';
import { TransferApproval } from '../../../features/approvals/approvals.model';
import dayjs from 'dayjs';
import RestoreUserConfirmationModal from '../../modal/RestoreUserConfirmationModal';

import './OverviewTable.scss';
import GenerateStudentCodeModal from '../../modal/GenerateStudentCode.modal';
import { userSelectors } from '../../../features/user/user.slice';


interface OverviewTableProps {
  user?: User;
  overview?: UserOverview;
  refreshUserDetail: () => void;
  printPageCallback: () => void;
}

const OverviewTable: React.FC<OverviewTableProps> = ({
  user,
  overview,
  refreshUserDetail,
  printPageCallback,
}) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const adminUser = useAppSelector(userSelectors.selectUser);

  if (!overview) {
    overview = EmptyUserOverview;
  }

  const [
    editing,
    setEditing,
  ] = useState(false);
  const [
    showRestoreConfirmDialog,
    setShowRestoreConfirmDialog,
  ] = useState(false);
  const [
    showGenerateStudentCodeModal,
    setShowGenerateStudentCodeModal,
  ] = useState(false);
  const [
    resetCode,
    setResetCode,
  ] = useState({
    token: '',
    expire: '',
  });

  if (!user) {
    return <></>;
  }

  const isApprovalNeeded = overview.isStudent && user.status?.emailVerified && (user.status?.accountApproved === undefined);
  const transferDetail = user.transferRequests && user.transferRequests[0];
  const isTransferApprovalNeeded = overview.isStudent && transferDetail && transferDetail.approved === undefined || false;

  const onRestoreUser = () => {
    setShowRestoreConfirmDialog(false);
    if (!user.id) return;

    dispatch(restoreDeletedUser(user.id))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully restored user.',
        }));
        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to restore user.',
        }));
      });
  };


  const handleGenerateCode = () => {
    setShowGenerateStudentCodeModal(true);
    if (user.id) {
      dispatch(generateConfirmationCode(user.id)).unwrap()
        .then((res) => {
          setResetCode(res);
          setShowGenerateStudentCodeModal(true);
        })
        .catch(() => {
          setAlert({
            type: 'error',
            message: 'There was an error generating a code.',
          });
        });
    }
  };

  const handleGenerateCodePermission = (): boolean => {
    if (!user.isStudent) {
      return false;
    }
    if (adminUser.roles.includes(UserRole.institutionAdmin) || adminUser.isAdultAdmin) {
      return false;
    }
    return canUseAuthComponent(adminUser.roles, [
      UserRole.admin,
      UserRole.districtAdmin,
      UserRole.institutionAdmin,
      UserRole.highSchoolCounselor,
      UserRole.highSchoolKeyContact,
      UserRole.middleSchoolCounselor,
      UserRole.middleSchoolKeyContact,
    ]);
  };

  const getSummary = () => (
    <div className="overview-summary flex_col">
      <div className="flex_row_jbetween">
        <div className="flex_row_jstart_acenter">
          <div className="back_arrow flex_acenter" onClick={() => navigate(-1)}>
            <ChevronLeftIcon className="carousel-icon" />
          </div>
          <div className="flex_row_acenter">{startCase(getGenericUserType(user.roles))} Details</div>
          {!editing && (
            <IconButton onClick={printPageCallback}>
              <LocalPrintshopOutlinedIcon />
            </IconButton>
          )}
        </div>

        {!editing && (
          <div className="flex_row_jend_acenter">
            {isApprovalNeeded && (
              <ApproveRejectCard
                onApprove={() => onApproveStudent()}
                onReject={() => onRejectStudent()}
              />
            )}

            {user.deletedAt ? (
              <div className="deleted-info flex_acenter">
                <p>Date Deleted: {dayjs(user.deletedAt).format('MM/DD/YYYY')}</p>
                <Button className="restore-user-btn"
                  variant="contained"
                  onClick={() => setShowRestoreConfirmDialog(true)}
                >
                  Restore User
                </Button>
              </div>
            ) : isTransferApprovalNeeded && (
              <ApproveRejectCard
                approveText="Transfer Approval Needed"
                onApprove={() => onApproveTransfer()}
                onReject={() => onRejectTransfer()}
              />
            )}

            {handleGenerateCodePermission() && (
              <span className="generate-code flex_row_jcenter_acenter">
                <p onClick={handleGenerateCode}>
                  Generate Code
                </p>

                <Tooltip
                  title="Use this if a user is unable to access their account and cannot use their email to reset their password."
                  className="code-tooltip"
                  arrow
                  placement="bottom"
                >
                  <img src={AppAssetPaths.icons.INFO} alt="info" />
                </Tooltip>
              </span>
            )}
            <Button className="overview-summary-edit-btn"
              variant="outlined"
              onClick={() => setEditing(true)}
            >
              Edit
            </Button>
          </div>
        )}
      </div>

      {(!editing && isTransferApprovalNeeded && !user.deletedAt) && (
        <div className="transfer-info flex_row_acenter">
          <img src={AppAssetPaths.icons.INFO_RED} alt="Info" />
          <span><strong>School Transfer Details</strong></span>
          <span>Current School: <strong>{transferDetail?.currentSchool}</strong></span>
          <span className="split-line">|</span>
          <span>New School: <strong>{transferDetail?.newSchool}</strong></span>
        </div>
      )}
    </div>
  );

  const onApproveStudent = () => {
    if (!user?.status?.approvalId) return;

    dispatch(approveStudent([
      user?.status?.approvalId,
    ]))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully approved the student.',
        }));

        refreshUserDetail();
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to approve the student.',
        }));
      });
  };

  const onRejectStudent = () => {
    if (!user?.status?.approvalId) return;

    dispatch(rejectStudent([
      user?.status?.approvalId,
    ]))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully rejected the student.',
        }));

        refreshUserDetail();
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to reject the student.',
        }));
      });
  };

  const onApproveTransfer = () => {
    const ids = user?.transferRequests?.map((item: TransferApproval) => item.id) || [];
    dispatch(approveTransfer(ids))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully approved the transfer.',
        }));

        refreshUserDetail();
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to approve the transfer.',
        }));
      });
  };

  const onRejectTransfer = () => {
    const ids = user?.transferRequests?.map((item: TransferApproval) => item.id) || [];
    dispatch(rejectTransfer(ids))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Successfully rejected the transfer.',
        }));

        refreshUserDetail();
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to reject the transfer.',
        }));
      });
  };

  const getDetails = () => {
    if (editing) {
      return (
        <EditUserDetail
          user={user}
          overview={overview}
          refreshUserDetail={refreshUserDetail}
          setEditing={setEditing}
        />
      );
    }
    else {
      if (overview?.isStudent) {
        return <StudentDetail user={user} overview={overview} />;
      }
      else {
        return <UserDetail user={user} overview={overview} />;
      }
    }
  };

  return (
    <>
      <ExpandableCard
        className="user-overview"
        summary={getSummary()}
        details={getDetails()}
      />

      <GenerateStudentCodeModal
        code={resetCode.token}
        expireDate={resetCode.expire}
        open={showGenerateStudentCodeModal}
        onClose={() => setShowGenerateStudentCodeModal(false)}
      />

      <RestoreUserConfirmationModal
        open={showRestoreConfirmDialog}
        onConfirm={onRestoreUser}
        onClose={() => setShowRestoreConfirmDialog(false)}
      />
    </>
  );
};

export default OverviewTable;
