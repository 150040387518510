/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box, Button, Pagination, TextField
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, {
  useEffect, useState
} from 'react';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { newUsersColumns } from '../../../constants/tables/bulkUploadUsersTable.types';
import { TableState } from '../../../constants/tables/table.types';
import { bulkAccountCreationSelectors } from '../../../features/bulkAccountCreation/bulkAccountCreation.slice';
import './BulkCreateStepFour.scss';
import { BulkUser } from '../../../features/bulkAccountCreation/bulkAccountCreation.model';
import {
  FormProvider, SubmitHandler, useFieldArray, useForm
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import TextInput from '../../../components/forms/TextInput';
import { AppAssetPaths } from '../../../app/app.types';
import { verifyEmails } from '../../../features/bulkAccountCreation/bulkAccountCreation.api';

interface BulkCreateStepFourProps {
  bulkUploadId: number,
}

const BulkCreateStepFour: React.FC<BulkCreateStepFourProps> = ({ bulkUploadId }) => {

  const dispatch = useAppDispatch();
  const newUsers = useAppSelector(bulkAccountCreationSelectors.newUsers);
  const editMode = useAppSelector(bulkAccountCreationSelectors.editMode);
  const pendingValidation = useAppSelector(bulkAccountCreationSelectors.pendingValidation);
  const allowStepFive = useAppSelector(bulkAccountCreationSelectors.allowStepFive);

  const [
    tableState,
    setTableState,
  ] = useState<TableState>({
    page: 1,
    pageSize: 100,
    columns: newUsersColumns,
    rows: [],
    loading: false,
  });

  const [
    newUsersList,
    setNewUsersList,
  ] = useState<BulkUser[]>([]);

  const [
    showValidationMessage,
    setShowValidationMessage,
  ] = useState<boolean>(false);


  useEffect(() => {
    setNewUsersList(newUsers);
  }, [
    newUsers,
  ]);

  useEffect(() => {
    // This dispatch is needed so the wrapper can check current newUsersList (i.e. dont allow next if user deletes all entries)
    dispatch(bulkAccountCreationSelectors.setAllowStepFive({
      allow: false,
      data: newUsersList,
    }));

    setTableState((prev) => ({
      ...prev,
      loading: false,
      rows: newUsersList,
    }));

  }, [
    newUsersList,
  ]);

  useEffect(() => {
    if (editMode) {
      prePopulateValues();
    }
  }, [
    editMode,
  ]);

  useEffect(() => {
    if (pendingValidation) {
      // Handle Validation
      handleSubmit(submitForm);
    }
  }, [
    pendingValidation,
  ]);

  const prePopulateValues = async () => {
    if (newUsers.length && !sectionFields.length) {
      newUsers.map((s: BulkUser) => appendSection(s));
    }
  };

  const sectionValidation = yup.array().of(
    yup.object().shape({
      id: yup.string(),
      firstName: yup.string()
        .required('First Name is required')
        .min(2),
      lastName: yup.string()
        .required('Last Name is required')
        .min(2),
      email: yup.string()
        .email('Must be a valid email')
        .required('Email is required')
        .min(4),
      grade: yup.string()
        .required('Grade is required')
        .test(
          'grade_test',
          'Invalid Grade',
          (value: any) => ((parseInt(value) >= 6 && parseInt(value) <= 12) ? true : false)
        )
        .min(1),
    })
  );


  const formValidationSchema = yup.object().shape({
    sections: sectionValidation,
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });

  const { control, handleSubmit, setError, formState: { errors } } = methods;
  const {
    fields: sectionFields,
    append: appendSection,
  } = useFieldArray({
    name: 'sections',
    control,
  });

  const NoNewUsersOverlay = () => (
    <h4>No new users</h4>
  );

  function findDuplicateEmails(emailsArray: string[]) {
    return emailsArray.filter((currentValue, currentIndex) => emailsArray.indexOf(currentValue) !== currentIndex);
  }

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    // // formSections will be undefined if the user has not made any edits
    let formSections = values?.sections;
    // // Need to make sure user has not entered edit mode to delete a user from the list
    if (formSections && (formSections.length !== newUsersList.length)) {
      // NewUsersList is source of truth for the deleted users
      const newUserListIds = newUsersList.map((el: BulkUser) => { return el.id; });
      formSections = formSections.filter((el: any) => {
        if (newUserListIds.indexOf(el.id) !== -1) {
          return el;
        }
      });
    }
    // eslint-disable-next-line no-extra-boolean-cast
    const submitSections = !!formSections.length ? formSections : newUsersList;

    // // Since confirm New Users button clicked, we want to update the newUsersList to their entries
    setNewUsersList(submitSections);

    // // If all validation passses, step five is allowed (which triggers next page)
    if (!allowStepFive.allow) {

      // Check for duplicates and invalid emails (pre-exisiting)
      const submitEmails = submitSections.map((el: any) => { return el.email; });
      const invalidEmails = await verifyEmails(submitEmails);
      const duplicateEmails = findDuplicateEmails(submitEmails);

      if (invalidEmails.length > 0 || duplicateEmails.length > 0) {
        const badEmails = invalidEmails.concat(duplicateEmails);
        const invalidSections = submitSections.filter((val: any) => badEmails.includes(val.email));

        invalidSections.map((ele: any) => {
          const formInd = submitSections.findIndex((el: any) => el.email == ele.email);
          setError(`sections.${formInd}.email`, {
            type: 'custom',
            message: 'Invalid Email Found',
          });
        });

        setShowValidationMessage(true);

      } else {
        // If no invalid emails, check form validation
        try {
          const formValidation = await sectionValidation.validate(submitSections);
          if (formValidation) {
            // No email or validation errors, allow step five
            dispatch(bulkAccountCreationSelectors.setAllowStepFive({
              allow: true,
              data: submitSections,
            }));
          }
        } catch (err) {
          // Validation errors that still need to be handled by the User. 
          setShowValidationMessage(true);
        }
      }

      // Set pending Validation back to false
      dispatch(bulkAccountCreationSelectors.setPendingValidation(false));
    }
  };

  useEffect(() => {
    setShowValidationMessage(!!errors?.sections);
  }, [
    errors,
  ]);



  return (
    <div className="step-four">
      <h3>Instructions:</h3>
      <p>1. Review the accounts that will be created on the table below. You have the ability to edit or delete users.</p>
      <p>2. Once the list has been reviewed, click “Confirm New Users.”</p>
      {newUsersList && (
        <div className="new-users-counter-banner">
          <div className="newUsers">New Users</div>
          <div className="counter">{`Total: ${newUsersList.length}`}</div>
        </div>
      )}
      {newUsersList && (
        <form className="new-users" onSubmit={handleSubmit(submitForm)}>
          {editMode ? (
            <FormProvider {...methods}>
              <Box className="table">
                <ul className="column-labels">
                  <span className="names">FIRST NAME</span>
                  <span className="names">LAST NAME</span>
                  <span className="email">EMAIL</span>
                  <span className="school">SCHOOL</span>
                  <span className="grade">GRADE</span>
                  <span className="trash"></span>
                </ul>
                {sectionFields.map((s: any, i) => (
                  <div key={s.id} className="display-rows">
                    <TextInput
                      name={`sections.${i}.firstName` as 'sections.0.firstName'}
                      errorMessage={(errors?.sections as any)?.[i]?.firstName?.message}
                      type="text"
                      size="small"
                      containerClass="names pad-right"
                      required />

                    <TextInput
                      name={`sections.${i}.lastName` as 'sections.0.lastName'}
                      errorMessage={(errors?.sections as any)?.[i]?.lastName?.message}
                      type="text"
                      size="small"
                      required
                      containerClass="names pad-right"
                    />

                    <TextInput
                      name={`sections.${i}.email` as 'sections.0.email'}
                      errorMessage={(errors?.sections as any)?.[i]?.email?.message}
                      type="text"
                      size="small"
                      containerClass="email pad-right"
                      required />

                    <TextField
                      className="school pad-right flex_row_jstart_acenter"
                      type="text"
                      size="small"
                      disabled={true}
                      value={`${s.school}`}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />

                    <TextInput
                      name={`sections.${i}.grade` as 'sections.0.grade'}
                      errorMessage={(errors?.sections as any)?.[i]?.grade?.message}
                      type="text"
                      size="small"
                      containerClass="grade pad-right"
                      required />

                    <div className="trash">
                      <img src={AppAssetPaths.icons.TRASH} alt="delete" onClick={() => {
                        const filteredList = newUsersList.filter((el) => { return el.id !== s.id; });
                        setNewUsersList(filteredList);
                      }} />
                    </div>
                  </div>
                ))}
              </Box>
            </FormProvider>
          ) : (

            <DataGrid
              className="table"
              {...tableState}
              components={{
                Pagination,
                NoRowsOverlay: NoNewUsersOverlay,
              }}
              columns={newUsersColumns}
              componentsProps={{
                pagination: {
                  page: tableState.page,
                  count: Math.ceil(newUsersList.length / tableState.pageSize),
                  onChange: (_: void, page: number) => {
                    setTableState((prev) => ({
                      ...prev,
                      page,
                    }));
                  },
                },
              }}
              onCellClick={(event) => {
                if (event.field === 'delete') {
                  const filteredList = newUsersList.filter((el) => { return el.id !== event.id; });
                  setNewUsersList(filteredList);
                }
              }}
              pagination
              paginationMode="server"
              sortingMode="server"
              disableColumnFilter
              disableColumnMenu
              hideFooterSelectedRowCount
              hideFooter />
          )}
          <div className="stepper-nav">
            {showValidationMessage && (
              <p className="pending-validation">Fill in the missing data in the highlighted fields</p>
            )}

            <Button
              className="edit-mode"
              name="edit-mode"
              variant="outlined"
              onClick={() => {
                dispatch(bulkAccountCreationSelectors.setEditMode(!editMode));
              }}
              disabled={editMode || allowStepFive.data.length === 0}
              disableElevation
            >Edit
            </Button>



            <Button
              variant="contained"
              type="submit"
              onClick={() => {
                setShowValidationMessage(editMode);
                dispatch(bulkAccountCreationSelectors.setPendingValidation(true));
              }}
              // disabled={handleNextButtonDisabled()}
              disableElevation
            >
              Confirm New Users
            </Button>
          </div>
        </form>
      )}

    </div>
  );
};

export default BulkCreateStepFour;